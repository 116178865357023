import { ROUTES } from "@/constants/routes"
import { loginMiddleware } from "./_middleware"

export const mypageRoutes = [
  {
    path: "/mypage",
    name: ROUTES.MYPAGE.INDEX,
    component: () => import("@/views/mypage/Index.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/edit",
    name: ROUTES.MYPAGE.EDIT,
    component: () => import("@/views/mypage/Edit.vue"),
  },
  {
    path: "/mypage/myinfo",
    name: ROUTES.MYPAGE.MYINFO,
    component: () => import("@/views/mypage/Myinfo.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/interestList",
    name: ROUTES.MYPAGE.INTEREST_LIST,
    component: () => import("@/views/mypage/InterestList.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/interviewHistory",
    name: ROUTES.MYPAGE.INTERVIEW_HISTORY,
    component: () => import("@/views/mypage/InterviewHistory.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/passwordEdit",
    name: ROUTES.MYPAGE.PASSWORD_EDIT,
    component: () => import("@/views/mypage/PasswordEdit.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/passwordInput",
    name: ROUTES.MYPAGE.PASSWORD_INPUT,
    component: () => import("@/views/mypage/PasswordInput.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/quit",
    name: ROUTES.MYPAGE.QUIT,
    component: () => import("@/views/mypage/Quit.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/reportHistory",
    name: ROUTES.MYPAGE.REPORT_HISTORY,
    component: () => import("@/views/mypage/ReportHistory.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/curriculum/early",
    name: ROUTES.MYPAGE.TEACHER_CURRICULUM_EARLY,
    component: () => import("@/views/mypage/teacher/Curriculum.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/curriculum/early/detail",
    name: ROUTES.MYPAGE.TEACHER_CURRICULUM_EARLY_DETAIL,
    component: () => import("@/views/mypage/teacher/CurriculumDetail.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/curriculum/regular",
    name: ROUTES.MYPAGE.TEACHER_CURRICULUM_REGULAR,
    component: () => import("@/views/mypage/teacher/Curriculum.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/curriculum/regular/detail",
    name: ROUTES.MYPAGE.TEACHER_CURRICULUM_REGULAR_DETAIL,
    component: () => import("@/views/mypage/teacher/CurriculumDetail.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/extracurricular",
    name: ROUTES.MYPAGE.TEACHER_EXTRACURRICULAR,
    component: () => import("@/views/mypage/teacher/ExtraCurricular.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/managestudent",
    name: ROUTES.MYPAGE.TEACHER_MANAGE_STUDENT,
    component: () => import("@/views/mypage/teacher/ManageStudent.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/manageteacher",
    name: ROUTES.MYPAGE.TEACHER_MANAGE_TEACHER,
    component: () => import("@/views/mypage/teacher/ManageTeacher.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/gesigterclass",
    name: ROUTES.MYPAGE.TEACHER_REGISTER_CLASS,
    component: () => import("@/views/mypage/teacher/RegisterClass.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/gesigterstudent",
    name: ROUTES.MYPAGE.TEACHER_REGISTER_STUDENT,
    component: () => import("@/views/mypage/teacher/RegisterStudent.vue"),
    beforeEnter: [loginMiddleware],
  },
  {
    path: "/mypage/teacher/gesigterteacher",
    name: ROUTES.MYPAGE.TEACHER_REGISTER_TEACHER,
    component: () => import("@/views/mypage/teacher/RegisterTeacher.vue"),
    beforeEnter: [loginMiddleware],
  },
]
